<template>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        Generar Etiqueta
                        <v-spacer></v-spacer>
                        <v-btn class="" @click="fetchData"  text icon color="blue">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-card outlined tile elevation="1">
                            <v-card-text style="padding-bottom: 0px;">
                                <div class="d-flex justify-space-between">
                                    <div class="d-flex flex-column">
                                    <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Filtro por rango de fecha</span>
                                    <v-menu
                                        ref="menu1"
                                        v-model="menu1"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                        style="border-bottom: 1px solid #000;"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-bind="attrs" v-on="on" placeholder="Todos" clearable @click:clear="limpiarRango" v-model="dateRangeText" solo readonly ></v-text-field>
                                        </template>
                                        <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates" ></v-date-picker>
                                    </v-menu>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-card-text>
                    <v-card-text>
                        <h3 class="title">Seleccione el(los) pedido(s) que desea generar etiqueta:</h3>
                        <v-data-table
                            :headers="headers"
                            :items="table"
                            show-select
                            v-model="selected"
                            no-results-text="No hay resultados disponibles"
                            no-data-text="No hay datos disponibles"
                            loading-text="Cargando datos..."
                            class="elevation-1 datatable"
                        >
                            <template v-slot:item.ingreso="{ item }">
                                
                                <td class="text-center">
                                    {{ $moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a') }}
                                </td>
                                
                            </template>
                            <template v-slot:item.estado="{ item }">
                                <td class="text-center">
                                    <b-button size="sm"  variant="info">{{item.order_state.name}}</b-button>
                                </td>
                            </template>
                        </v-data-table>
                        <br><br>
                        <v-select
                            v-model="selectCourier"
                            :items="couriersList"
                            :rules="[v => !!v || 'Debes elegir un courier']"
                            label="Seleccione un courier"
                            required
                        ></v-select>
                        <br><br>
                        <v-btn @click="finish" v-show="selected.length && selectCourier" small color="success" dark class="float-left"
                        ><v-icon left dark>mdi-format-list-checks</v-icon> Generar etiqueta</v-btn>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import format from 'date-fns/format';
export default {
    data(){
        return {
            dates: [],
            menu1: false,
            dateRangeText: "",

            search: '',
            table: [],
            
            selected: [],
            couriersList: [],
            selectCourier: undefined,
        }
    },
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Generar etiqueta", route: "/envios/generar-etiqueta" },
        ]);
        this.fetchData();
        this.fetchCouriers();
    },
    methods: {
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat)
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat)
            }
            return formattedDates
        },
        finish()
        {
            console.log('finish');
            this.axios({
                url: 'orders/bulk_generate_etiqueta',
                method: 'POST',
                data: {
                    orders: this.selected.map( e => e.id ),
                    courier: this.selectCourier
                }
            }).then( response => {
                this.fetchData();
            }).catch( error => {
                console.log(error);
            });
        },
        filtroRangoFechas(value) {
            if (!this.dateRangeText || !this.dates.length) {
                return true;
            } else {
                if (
                this.$moment(value).isSameOrAfter(this.$moment(this.dates[0])) &&
                this.$moment(value).isSameOrBefore(this.$moment(this.dates[1]))
                ) {
                return true;
                } else {
                return false;
                }
            }
        },
        formatearRango() {
            if (!this.dates.length) {
                this.dateRangeText = "";
            } else {
                this.dateRangeText = this.dates.join(" ~ ");
            }
        },
        limpiarRango() {
            this.dates = [];
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'orders/by_state/DEFAULT_OUT_PACKAGING',
                method: 'POST',
                data: {
                    store: 1
                }
            }).then( response => {
                vm.table = response.data.data;
            }).catch( error => {
                console.log(error);
            });
        },
        fetchCouriers(){
            var vm = this;
            this.axios({
                method: 'GET',
                url: 'shippings/couriers/data_select_input'
            }).then( response => {
                vm.couriersList = response.data;
            }).catch( error => {
                console.log(error);
            });
        }
    },
    computed: {
        headers(){ 
            return [
                {
                text: '#...',
                align: 'left',
                sortable: true,
                value: 'id',
                },
                { text: 'Referencia', value: 'order_reference' },
                { text: 'Fecha Ingreso', value: 'ingreso', filter: (v) => this.filtroRangoFecha(v) },
                { text: 'Estado', value: 'estado' },
                { text: 'Fuente', value: 'fuente' },
            ]
        }
    }
}
</script>